.wallet-adapter-button {
    background-color: transparent;
    color: #00ff00;
    font-family: 'Trebuchet MS', 'Helvetica', sans-serif;
    font-size: 16px;
    font-weight: 600;
    height: 48px;
    padding: 0 24px;
    border-radius: 4px;
}

.wallet-adapter-button-trigger {
    background-color: #004400;
}

.wallet-adapter-button:not([disabled]):hover {
    background-color: #006600;
}

.wallet-adapter-button[disabled] {
    background: #001a00;
    color: #004400;
}

.wallet-adapter-dropdown-list {
    background: #001a00;
    border: 2px solid #00ff00;
}

.wallet-adapter-dropdown-list-item {
    color: #00ff00;
}

.wallet-adapter-dropdown-list-item:not([disabled]):hover {
    background-color: #004400;
}

.wallet-adapter-modal {
    background: rgba(0, 26, 0, 0.8);
}

.wallet-adapter-modal-button-close {
    background: #004400;
}

.wallet-adapter-modal-button-close svg {
    fill: #00ff00;
}

.wallet-adapter-modal-wrapper {
    background: #001a00;
    border: 2px solid #00ff00;
}

.wallet-adapter-modal-title {
    color: #00ff00;
}

.wallet-adapter-modal-list .wallet-adapter-button {
    background-color: #002200;
}

.wallet-adapter-modal-list-more {
    color: #00ff00;
}

.wallet-adapter-modal-list-more svg {
    fill: #00ff00;
}

.wallet-adapter-modal-middle-button {
    background-color: #004400;
    color: #00ff00;
}